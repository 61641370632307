.formopt{
  width: 90%;
  margin-left: 5%;
  margin-top:110px;
}
.form-head{
  color: #e31e24;
  font-size: 30px;
}
.form-btn{
  background-color: #e31e24;
  color: aliceblue;
  border:none;
}

.form-btn:hover{
  background-color: #ad1419;
  color: aliceblue;
  border:none;
}
.formmap{
  height: 600px;
}

@media only screen and (max-width: 1400px) {
  .formopt{
    width: 90%;
    margin-left: 5%;
    margin-top:100px;
  }
}

@media only screen and (max-width: 1100px) {
  .formcont{
    margin-top:-30px;
  }
}

@media only screen and (max-width: 1100px) {
  .formopt{
    width: 90%;
    margin-left: 5%;
    margin-top:80px;
  }
  .form-head{
    font-size: 25px;
  }
}

@media only screen and (max-width: 700px) {
  .formopt{
    text-align: center;
  }
  .formopt li{
    text-align: left;
  }
  .listhead{
    text-align: left;
  }
}
@media only screen and (max-width: 600px) {
  .form-head{
    font-size: 18px;
  }
}
@media only screen and (max-width: 400px) {
  .formopt{
    width: 100%;
    margin-left: 0%;
    margin-top:70px;
  }
}

