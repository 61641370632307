.footer{
    background-color: #1b4e9b;
    color:white;
    width:100%;
    text-align:center;
    padding:20px;
  }
  .footer1{
    padding:3% 8%;
    text-align:left;
  }
  .footer_body{
    color:#e7e7e7;
    font-family:Karla;
    font-weight:400;
    font-size:18px;
  }
  .footer_head{
    color:rgb(255, 255, 255);
    font-family:Roboto;
    font-weight:500;
    letter-spacing: 1.5px;
    font-size:22px;
    padding:15px;
  }
  .footer li{
    list-style-type: circle;
    padding:5px 0;
  }
  
  
  .footer a{
    text-decoration: none;
    color:#e7e7e7;
  }
  
  a:hover{
    cursor: pointer;
    color: rgb(255, 138, 138);
  }
  
  
  @media only screen and (min-width: 10px)and (max-width: 1000px){
    .footer_body{
      color:#e7e7e7;
      font-family:Karla;
      font-weight:400;
      font-size:15px;
    }
    .footer_head{
      color:rgb(255, 255, 255);
      font-family:Roboto;
      font-weight:500;
      letter-spacing: 1.5px;
      font-size:20px;
      padding:15px;
    }
    .footer1{
      padding:0% 8%;
      text-align:left;
    }
    .copyright{
      font-size: 12px;
    }
  }