.review{
  margin:80px 10% 40px 10%;
}

.review-cards{
  background-color: rgb(255, 247, 232);
  height: 200px;
  padding: 5%;
  border-radius: 10px;
}
.review-card-lvl1{
  align-items: center;
}
.user{
  width:12%;
}

.review-name{
  font-size: 18px;
}

.review-date{
  font-size: 14px;
}

.review-desc{
  font-size: 16px;
}

.star{
  width:40%;
}