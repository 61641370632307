@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.navbar{
  background-color: #1b4e9b;
  width:100%;
  height:80px;
  position: fixed;
  top:30px;
  z-index: 10;
}
.navbar_name1{
  display:none
}
.navbar_name{
  color:white;
  position: relative;
  font-family: roboto;
  font-size: 25px;
  text-align: center;
}
span a{
  text-decoration: none;
  color: #ffffff;
}
.navmenu{
  height:80px;
  align-items: top;
  color: white;
  margin-right: 50px;
  font-size: 18px;
}

.navmenu span{
  margin:0 20px ;
}

.navmenu span:hover{
  cursor: pointer;
  color: rgb(255, 138, 138);
}

.menu{
  display:none;
}

.sidenav-menu{
  display: none;
}

@media only screen and (max-width: 1400px) {
  .navmenu{
    height:80px;
    margin-top:-20px;
    color: white;
    font-size: 16px;
  }
  .navbar{
    background-color: #1b4e9b;
    width:100%;
    height:70px;
    position: fixed;
    top:30px;
    z-index: 10;
  }
  .logo{
    height:120px;
  }
  .navbar_name1{
    display:none
  }
  .navbar_name{
    color:white;
    position: relative;
    font-family: roboto;
    font-size: 20px;
    text-align: center;
  }
  
  .navmenu{
    height:70px;
    align-items: top;
    color: white;
  }

  .navmenu span{
    margin:0 14px ;
  }
  .menu{
    display:none;
  }
  
  .sidenav-menu{
    display: none;
  }

}


@media only screen and (max-width: 1300px) {
  
  .menu{
    display:none;
  }

  .navmenu{
    height:80px;
    margin-top:0px;
    color: white;
    font-size: 15px;
  }
  
  .menu:hover{
    cursor: pointer;
  }
  
  .sidenav-menu{
    display: none;
  }

}

@media only screen and (max-width: 1000px) {
  .navmenu{
    height:80px;
    margin-top:0px;
    color: white;
    font-size: 14px;
    margin-right: 10px;
  }
  .navbar{
    background-color: #1b4e9b;
    width:100%;
    height:50px;
    position: fixed;
    top:30px;
    z-index: 10;
  }
  .logo{
    height:80px;
  }
  .navbar_name{
    display:none
  }
  .navbar_name1{
    display: block;
    color:white;
    position: relative;
    font-family: roboto;
    font-size: 16px;
    text-align: center;
    margin-top: 5px;
  }
  .navmenu{
    height:70px;
    align-items: top;
    color: white;
  }

  .sidenav-menu{
    display: none;
  }

}

@media only screen and (max-width: 800px) {
  .navmenu{
    height:80px;
    margin-top:-20px;
    color: white;
    font-size: 14px;
  }
  .navbar{
    background-color: #1b4e9b;
    width:100%;
    height:50px;
    position: fixed;
    top:30px;
    z-index: 10;
  }
  .logo{
    height:80px;
  }
  .navbar_name{
    display:none
  }
  .navbar_name1{
    display: block;
    color:white;
    position: relative;
    font-family: roboto;
    font-size: 16px;
    text-align: center;
    margin-top: 5px;
  }
  .navmenu{
    height:70px;
    align-items: top;
    color: white;
  }

  .navmenu span{
    display: none;
  }
  .menu{
    display:block;
    color: aliceblue;
    width:40px;
    height:40px;
    margin-top:5px;
  }

  .sidenav-menu{
    top:0;
    height:100vh;
    width:0;
    display: flex;
    flex-direction: column;
    background-color: #1b4e9b;
    z-index: 10;
    position:fixed;
    overflow-x: hidden;
    transition: width 0.5s;
    text-align:center;
  }

  .sidenav-links {
    display:flex;
    flex-direction:column;
  }

  .sidenav-menu a {
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    font-size: 24px;
    font-family:Roboto;
    font-weight:400;
    letter-spacing: 1.5px;
    margin-top: 10%;
  }
  
  .sidenav-close{
    font-size: 24px;
    background-color: azure;
    width:40px;
    height:40px;
    font-weight: 700;
    text-align: center;
    align-items: center;
    border-radius: 50%;
    margin:10px;
    cursor: pointer;
  }
  .sidenav a:hover {
    cursor:pointer;
    color: #ad1919;
  }
}
@media only screen and (max-width: 500px) {
  .navmenu{
    height:80px;
    margin-top:-20px;
    color: white;
    font-size: 14px;
  }
  .navbar{
    background-color: #1b4e9b;
    width:100%;
    height:50px;
    position: fixed;
    top:30px;
    z-index: 10;
  }
  .logo{
    height:80px;
  }
  .navbar_name{
    display:none
  }
  .navbar_name1{
    display: block;
    color:white;
    position: relative;
    font-family: roboto;
    font-size: 16px;
    text-align: center;
    margin-top: 5px;
  }
  .navmenu{
    height:70px;
    align-items: top;
    color: white;
  }

  .navmenu span{
    display: none;
  }
  .menu{
    display:block;
    color: aliceblue;
    width:40px;
    height:40px;
    margin-top:2px;
    margin-right:0px;
  }
}


@media only screen and (max-width: 400px) {
  .navmenu{
    height:80px;
    margin-top:-20px;
    color: white;
    font-size: 14px;
  }
  .navbar{
    background-color: #1b4e9b;
    width:100%;
    height:45px;
    position: fixed;
    top:30px;
    z-index: 10;
  }
  .logo{
    margin-top:10px;
    height:50px;
  }
  .navbar_name{
    display:none
  }
  .navbar_name1{
    display: block;
    color:white;
    position: relative;
    font-family: roboto;
    font-size: 16px;
    text-align: center;
    margin-top: 5px;
  }
  .navmenu{
    height:50px;
    align-items: top;
    color: white;
  }

  .navmenu span{
    display: none;
  }
  .navbar_name1{
    display: block;
    color:white;
    position: relative;
    font-family: roboto;
    font-size: 14px;
    text-align: center;
    margin-top: 5px;
  }
  .menu{
    display:block;
    color: aliceblue;
    width:40px;
    height:40px;
    margin-top:0px;
    margin-right:0px;
  }
}
