.hero{
  z-index: 0;
  margin-top:110px;
}

.welcome{
  font-family: roboto;
  margin:0 10%;
}
.welcome_heading{
  color: #1b4e9b;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 30px;
}

.welcome p{
  text-align: center;
}

.card h5{
  text-align: center;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 30%;
  position: relative;
  height: 0;
  width:60%;
  margin-left:20%;
  background-color: wheat;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.hero1{
  display:none;
}
@media only screen and (max-width: 1400px) {
  .hero{
    z-index: 0;
    margin-top:100px;
  }
  .card h5{
    text-align: center;
    font-size: 18px;
  }
  
.hero1{
  display:none;
}
}

@media only screen and (max-width: 1200px) {
  .card h5{
    text-align: center;
    font-size: 16px;
  }

  .welcome_heading{
    color: #1b4e9b;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  
.hero1{
  display:none;
}
}
@media only screen and (max-width: 1100px) {
  .hero{
    margin-top:80px;
  }
  
.hero1{
  display:none;
}
}
@media only screen and (max-width: 991px) {
.card{
  margin:20px;
}
.video-responsive {
  overflow: hidden;
  padding-bottom: 40%;
  position: relative;
  height: 0;
  width:80%;
  margin-left:10%;
  background-color: wheat;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.hero1{
  display:none;
}
}
@media only screen and (max-width: 600px) {
  .hero{
    display:none;
  }
  .hero1{
    margin-top:80px;
    display:block;
  }
}
@media only screen and (max-width: 500px) {

.video-responsive {
  overflow: hidden;
  padding-bottom: 70%;
  position: relative;
  height: 0;
  width:95%;
  margin-left:2.5%;
  background-color: wheat;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.welcome{
  font-family: roboto;
  margin:0 5%;
}
}


@media only screen and (max-width: 400px) {

  .hero1{
    margin-top:70px;
  }
  }