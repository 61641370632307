/* googleapis fonts */
@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Corinthia:wght@400;700&display=swap');

.topdiv{
  width:100%;
  background-color:azure;
  height:30px;
  align-items: center;
  position: fixed;
  top:0;
  z-index: 10;
}

.langcont{
  right:20px;
  position: absolute;
  align-items: center;
}

.langdd{
  border:none;
  margin-left: 5px;
}