.product{
  margin-top:110px;
}
.tabsprob{
  background-color: rgb(190, 184, 247);
}
.tablist{
  font-size: 20px;
  padding:0 10px;
  font-weight: 600;
}
.tabs{
  margin:60px 10%;
}
.tabpanel{
  margin:60px 3%;
}
.spec{
  font-size: smaller;
  color: rgb(80, 80, 80);
}
.pricespec{
  font-size:medium;
  color: rgb(80, 80, 80);
}
.pricered{
  color:#e31e24;
}
@media only screen and (max-width: 1400px) {
  .product{
    margin-top:100px;
  }
}

@media only screen and (max-width: 1100px) {
  .product{
    margin-top:80px;
  }
  
  .tablist{
    font-size: 18px;
  }
  .tabs{
    margin:40px 10%;
  }
}



@media only screen and (max-width: 600px) {
  .product{
    margin-top:80px;
  }
  
  .tablist{
    font-size: 16px;
  }

  .tabs{
    margin:40px 5%;
  }
}



@media only screen and (max-width: 400px) {
  .product{
    margin-top:70px;
  }
  .tablist{
    font-size: 14px;
  }
}
@media only screen and (max-width: 450px) {
  .tabs{
    margin:40px 0%;
  }
}